import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Root from './Root'
import Auth from '../Auth'
import CreateSchool from '../CreateSchool'

import SchoolDashboard from '../SchoolDashboard'
import Courses from '../Courses'
import NewCourse from '../NewCourse'
import CourseDisplay from '../CourseDisplay'
import EditCourse from '../EditCourse'

export default () => (
	<BrowserRouter>
		<Switch>
			<Route exact path="/" component={Root} />
			<Route exact path="/auth" component={Auth} />
			<Route exact path="/new" component={CreateSchool} />
			
			<Route exact path="/s/:slug" component={SchoolDashboard} />
			<Route exact path="/s/:slug/courses" component={Courses} />
			<Route exact path="/s/:slug/courses/new" component={NewCourse} />
			<Route exact path="/s/:slug/courses/:courseId" component={CourseDisplay} />
			<Route exact path="/s/:slug/courses/:courseId/edit" component={EditCourse} />
			
			<Route status={404} component={() => <h1>404</h1>} />
		</Switch>
	</BrowserRouter>
)
