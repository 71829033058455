import React from 'react'
import cx from 'classnames'

import School from '../../models/School'
import Course from '../../models/Course'
import Navbar from '../shared/SchoolNavbar'
import Content from './Content'
import Loader from '../shared/Loader'

import '../../scss/components/PublishCourse.scss'

export default <UserData,>(
	{ school, course }: {
		school: School<UserData> | undefined
		course: Course | null | undefined
	}
) => (
	<div className={cx('publish-course', { loading: !school })}>
		{school
			? school.isAdmin
				? (
					<>
						<Navbar school={school} />
						{course === undefined
							? <Loader size="24px" thickness="4px" color="#63b3ed" />
							: <Content school={school} course={course} />
						}
					</>
				)
				: <h1>You must be an admin</h1>
			: <Loader size="24px" thickness="4px" color="#63b3ed" />
		}
	</div>
)
