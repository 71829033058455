import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { faSignature } from '@fortawesome/free-solid-svg-icons'

import School from '../../models/School'
import Course from '../../models/Course'
import LoadingState from '../../models/LoadingState'
import Input from '../shared/Input'
import CKEditor from '../shared/CKEditor'
import Button from '../shared/Button'

export default <UserData,>(
	{ school, course }: {
		school: School<UserData>
		course: Course | null
	}
) => {
	const history = useHistory()
	
	const [name, setName] = useState(course?.name ?? '')
	const [description, setDescription] = useState(course?.description ?? '')
	const [syllabus, setSyllabus] = useState(course?.syllabus ?? '')
	
	const [publishLoadingState, setPublishLoadingState] = useState(LoadingState.None)
	const [deleteLoadingState, setDeleteLoadingState] = useState(LoadingState.None)
	
	const isPublishButtonLoading = publishLoadingState === LoadingState.Loading
	const isPublishButtonDisabled = !name
	
	const isDeleteButtonLoading = deleteLoadingState === LoadingState.Loading
	
	const publish = async () => {
		try {
			setPublishLoadingState(LoadingState.Loading)
			
			if (course) {
				await course.edit(school.id, {
					name,
					description,
					syllabus
				})
				
				setPublishLoadingState(LoadingState.Success)
				history.push(`/s/${school.slug}/courses/${course.id}`)
			} else {
				const courseId = await Course.createForSchoolWithId(school.id, {
					name,
					description,
					syllabus
				})
				
				setPublishLoadingState(LoadingState.Success)
				history.push(`/s/${school.slug}/courses/${courseId}/lessons`)
			}
		} catch (error) {
			setPublishLoadingState(LoadingState.Fail)
			
			alert(error.message)
			console.error(error)
		}
	}
	
	const deactivate = async () => {
		if (!(course && window.confirm(`Are you sure you want to deactivate ${course.name}?`)))
			return
		
		try {
			setDeleteLoadingState(LoadingState.Loading)
			
			await course.deactivate(school.id)
			
			setDeleteLoadingState(LoadingState.Success)
			history.push(`/s/${school.slug}/courses`)
		} catch (error) {
			setDeleteLoadingState(LoadingState.Fail)
			
			alert(error.message)
			console.error(error)
		}
	}
	
	return (
		<div className="content">
			<h1>{course ? 'Edit' : 'New'} Course</h1>
			<h3>Name</h3>
			<Input
				icon={faSignature}
				type="name"
				placeholder="Name"
				value={name}
				setValue={setName}
			/>
			<h3>Description</h3>
			<CKEditor
				schoolId={school.id}
				data={description}
				setData={setDescription}
			/>
			<h3>Syllabus</h3>
			<CKEditor
				schoolId={school.id}
				data={syllabus}
				setData={setSyllabus}
			/>
			<div className="buttons">
				<Button
					className="publish"
					loaderSize="16px"
					loaderThickness="3px"
					loaderColor="#63b3ed"
					loading={isPublishButtonLoading}
					disabled={isPublishButtonDisabled}
					onClick={publish}
				>
					{course ? 'Save' : 'Create'}
				</Button>
				{course && (
					<Button
						className="delete"
						loaderSize="16px"
						loaderThickness="3px"
						loaderColor="#e53e3e"
						loading={isDeleteButtonLoading}
						disabled={false}
						onClick={deactivate}
					>
						Deactivate
					</Button>
				)}
			</div>
		</div>
	)
}
