import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import HamburgerMenu from 'react-hamburger-menu'

import School from '../../models/School'
import useImageUrl from '../../hooks/useImageUrl'

import '../../scss/components/SchoolNavbar.scss'

export default <UserData,>({ school }: { school: School<UserData> }) => {
	const [imageUrl] = useImageUrl(school)
	
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	
	return (
		<div className="school-navbar">
			<div className="top">
				{imageUrl && <img src={imageUrl} alt={school.name} />}
				<h1>{school.name}</h1>
				<HamburgerMenu
					isOpen={isMenuOpen}
					menuClicked={() => setIsMenuOpen(!isMenuOpen)}
					color="black"
				/>
			</div>
			{isMenuOpen && (
				<div className="menu">
					<Link to={`/s/${school.slug}/courses`}>Course Catalog</Link>
					<Link to={`/s/${school.slug}/classes`}>Class Catalog</Link>
				</div>
			)}
		</div>
	)
}
