import { useContext, useEffect } from 'react'

import SchoolsContext from '../contexts/Schools'
import School from '../models/School'
import useCurrentUser from './useCurrentUser'
import {
	updateSchool,
	updateSchoolUserData,
	removeSchool,
	setIsObservingSchools
} from '../actions'
import { compose2, compose3 } from '../utils'

export default () => {
	const [{ schools, isObservingSchools }, dispatch] = useContext(SchoolsContext)
	
	const [currentUser] = useCurrentUser()
	
	useEffect(() => {
		if (!currentUser || isObservingSchools)
			return
		
		dispatch(setIsObservingSchools(true, false))
		
		School.observeForUserWithId(currentUser.id, false, {
			updateSchool: compose3(dispatch, updateSchool),
			updateSchoolUserData: compose2(dispatch, updateSchoolUserData),
			removeSchool: compose2(dispatch, removeSchool)
		})
	}, [currentUser, isObservingSchools]) // eslint-disable-line
	
	return schools
}
