import { useContext, useEffect } from 'react'

import CoursesContext from '../contexts/Courses'
import {
	initializeCourses,
	addCourse,
	updateCourse,
	removeCourse
} from '../actions'
import School from '../models/School'
import Course from '../models/Course'
import { compose1, compose2 } from '../utils'

export default <UserData>(school: School<UserData> | null | undefined): Course[] | null => {
	const [coursesState, dispatch] = useContext(CoursesContext)
	const courses = school && coursesState[school.id]
	
	useEffect(() => {
		if (!school || courses)
			return
		
		Course.observeForSchoolWithId(school.id, {
			initializeCourses: compose1(dispatch, initializeCourses),
			addCourse: compose2(dispatch, addCourse),
			updateCourse: compose2(dispatch, updateCourse),
			removeCourse: compose2(dispatch, removeCourse)
		})
	}, [school, courses]) // eslint-disable-line
	
	return courses ?? null
}
