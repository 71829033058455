import React, { HTMLAttributes } from 'react'
import { useHistory } from 'react-router-dom'

import firebase from '../../firebase'

import 'firebase/auth'

const auth = firebase.auth()

export default ({ children, ...props }: HTMLAttributes<HTMLButtonElement>) => {
	const history = useHistory()
	
	const signOut = async () => {
		try {
			await auth.signOut()
			
			history.push('/')
		} catch (error) {
			alert(error.message)
			console.error(error)
		}
	}
	
	return (
		<button {...props} onClick={signOut}>
			{children}
		</button>
	)
}
