import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'

import * as serviceWorker from './serviceWorker'
import { CurrentUserProvider } from './contexts/CurrentUser'
import { SchoolsProvider } from './contexts/Schools'
import { SchoolImageUrlsProvider } from './contexts/SchoolImageUrls'
import { CreateSchoolProvider } from './contexts/CreateSchool'
import { CoursesProvider } from './contexts/Courses'
import App from './components/App'
import firebase from './firebase'

import 'firebase/analytics'

import './scss/index.scss'

const analytics = firebase.analytics()
const root = document.getElementById('root') ?? document.body

analytics.logEvent('start')
Modal.setAppElement(root)

ReactDOM.render((
	<CurrentUserProvider>
		<SchoolsProvider>
			<SchoolImageUrlsProvider>
				<CreateSchoolProvider>
					<CoursesProvider>
						<App />
					</CoursesProvider>
				</CreateSchoolProvider>
			</SchoolImageUrlsProvider>
		</SchoolsProvider>
	</CurrentUserProvider>
), root)

serviceWorker.register()
