import React, { createContext, Dispatch, PropsWithChildren, useReducer } from 'react'

import Action, { ActionType } from '../actions/Action'
import LoadingState from '../models/LoadingState'

export interface SchoolImageUrlState {
	url: string | null
	loadingState: LoadingState
}

export type SchoolImageUrlsState = Record<string, SchoolImageUrlState>

export type SchoolImageUrlsAction = Action<
	| { schoolId: string, url: string | null } // SetSchoolImageUrl
	| { schoolId: string, loadingState: LoadingState } // SetSchoolImageUrlLoadingState
>

const initialState: SchoolImageUrlsState = {}

const reducer = (state: SchoolImageUrlsState, { type, payload }: SchoolImageUrlsAction) => {
	switch (type) {
		case ActionType.SetSchoolImageUrl: {
			const { schoolId, url } = payload as { schoolId: string, url: string | null }
			const oldState = state[schoolId]
			
			return {
				...state,
				[schoolId]: {
					url,
					loadingState: oldState ? oldState.loadingState : LoadingState.None
				}
			}
		}
		case ActionType.SetSchoolImageUrlLoadingState: {
			const { schoolId, loadingState } = payload as { schoolId: string, loadingState: LoadingState }
			const oldState = state[schoolId]
			
			return {
				...state,
				[schoolId]: {
					url: oldState ? oldState.url : null,
					loadingState
				}
			}
		}
		default:
			return state
	}
}

const Context = createContext<[SchoolImageUrlsState, Dispatch<SchoolImageUrlsAction>]>([initialState, console.log])
export default Context

export const SchoolImageUrlsProvider = ({ children }: PropsWithChildren<{}>) => (
	<Context.Provider value={useReducer(reducer, initialState)}>
		{children}
	</Context.Provider>
)
