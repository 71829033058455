import React, { createContext, Dispatch, PropsWithChildren, useReducer } from 'react'

import Action, { ActionType } from '../actions/Action'
import School from '../models/School'
import UserData from '../models/School/UserData'
import EnrolledUserData from '../models/School/EnrolledUserData'

export interface SchoolsState {
	schools: School<UserData>[]
	enrolledSchools: School<EnrolledUserData>[]
	isObservingSchools: boolean
	isObservingEnrolledSchools: boolean
}

export type SchoolsAction = Action<
	| {
		snapshot: firebase.firestore.DocumentSnapshot
		userDataSnapshot: firebase.firestore.DocumentSnapshot
		isEnrolled: boolean
	} // UpdateSchool
	| {
		snapshot: firebase.firestore.DocumentSnapshot
		isEnrolled: boolean
	} // UpdateSchoolUserData
	| { schoolId: string, isEnrolled: boolean } // RemoveSchool
	| { value: boolean, isEnrolled: boolean } // SetIsObservingSchools
>

const initialState: SchoolsState = {
	schools: [],
	enrolledSchools: [],
	isObservingSchools: false,
	isObservingEnrolledSchools: false
}

const reducer = (state: SchoolsState, { type, payload }: SchoolsAction) => {
	switch (type) {
		case ActionType.UpdateSchool: {
			const { snapshot, userDataSnapshot, isEnrolled } = payload as {
				snapshot: firebase.firestore.DocumentSnapshot
				userDataSnapshot: firebase.firestore.DocumentSnapshot
				isEnrolled: boolean
			}
			const key = isEnrolled ? 'enrolledSchools' : 'schools'
			const schools = state[key] as School<UserData | EnrolledUserData>[]
			
			return {
				...state,
				[key]: schools.some(school => school.id === snapshot.id)
					? schools.map(school =>
						school.id === snapshot.id
							? school.updateFromSnapshot(snapshot)
							: school
					)
					: [...schools, School.fromSnapshot(
						snapshot,
						(isEnrolled ? EnrolledUserData : UserData).fromSnapshot(userDataSnapshot)
					)]
			}
		}
		case ActionType.UpdateSchoolUserData: {
			const { snapshot, isEnrolled } = payload as {
				snapshot: firebase.firestore.DocumentSnapshot
				isEnrolled: boolean
			}
			const key = isEnrolled ? 'enrolledSchools' : 'schools'
			const schools = state[key] as School<UserData | EnrolledUserData>[]
			
			return {
				...state,
				[key]: schools.map(school =>
					school.id === snapshot.id
						? (school.userData?.updateFromSnapshot(snapshot), school)
						: school
				)
			}
		}
		case ActionType.RemoveSchool: {
			const { schoolId, isEnrolled } = payload as {
				schoolId: string
				isEnrolled: boolean
			}
			const key = isEnrolled ? 'enrolledSchools' : 'schools'
			const schools = state[key] as School<UserData | EnrolledUserData>[]
			
			return {
				...state,
				[key]: schools.filter(school =>
					school.id !== schoolId
				)
			}
		}
		case ActionType.SetIsObservingSchools: {
			const { value, isEnrolled } = payload as {
				value: boolean
				isEnrolled: boolean
			}
			
			return {
				...state,
				[isEnrolled ? 'isObservingEnrolledSchools' : 'isObservingSchools']: value
			}
		}
		default:
			return state
	}
}

const Context = createContext<[SchoolsState, Dispatch<SchoolsAction>]>([
	initialState,
	console.log
])
export default Context

export const SchoolsProvider = ({ children }: PropsWithChildren<{}>) => (
	<Context.Provider value={useReducer(reducer, initialState)}>
		{children}
	</Context.Provider>
)
