import React from 'react'
import { useParams } from 'react-router-dom'

import useSchools from '../../hooks/useSchools'
import PublishCourse from '../PublishCourse'

export default () => {
	const { slug } = useParams()
	
	const school = useSchools().find(school => school.slug === slug)
	
	return <PublishCourse school={school} course={null} />
}
