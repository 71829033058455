import React from 'react'
import { useParams } from 'react-router-dom'

import useSchools from '../../hooks/useSchools'
import useEnrolledSchools from '../../hooks/useEnrolledSchools'
import useCourses from '../../hooks/useCourses'
import PublishCourse from '../PublishCourse'

export default () => {
	const { slug, courseId } = useParams()
	
	const schools = useSchools()
	const enrolledSchools = useEnrolledSchools()
	
	const school = schools.find(school => school.slug === slug)
		?? enrolledSchools.find(school => school.slug === slug)
	
	const course = useCourses(school)?.find(course => course.id === courseId)
	
	return <PublishCourse school={school} course={course} />
}
