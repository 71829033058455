export default class UserData {
	isEnrolled = true
	
	static fromSnapshot = (snapshot: firebase.firestore.DocumentSnapshot) =>
		new UserData()
	
	updateFromSnapshot = (snapshot: firebase.firestore.DocumentSnapshot) => {
		return this
	}
}
