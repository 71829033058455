import React from 'react'
import { Link } from 'react-router-dom'

import School from '../../models/School'
import Course from '../../models/Course'

export default <UserData,>(
	{ school, course }: {
		school: School<UserData>
		course: Course
	}
) => (
	<>
		<Link to={`/s/${school.slug}/courses/${course.id}/edit`}>
			Edit
		</Link>
		<h1>{course.name}</h1>
		<div
			className="description"
			dangerouslySetInnerHTML={{ __html: course.description }}
		/>
		<div
			className="syllabus"
			dangerouslySetInnerHTML={{ __html: course.syllabus }}
		/>
	</>
)
