export enum SchoolRole {
	Admin = 'admin',
	Teacher = 'teacher'
}

export const decodeSchoolRole = (string: string) => {
	switch (string) {
		case 'admin':
			return SchoolRole.Admin
		case 'teacher':
			return SchoolRole.Teacher
		default:
			return SchoolRole.Teacher
	}
}

export default class UserData {
	isEnrolled = false
	
	role: SchoolRole
	
	constructor(role: SchoolRole) {
		this.role = role
	}
	
	static fromSnapshot = (snapshot: firebase.firestore.DocumentSnapshot) =>
		new UserData(decodeSchoolRole(snapshot.get('role')))
	
	updateFromSnapshot = (snapshot: firebase.firestore.DocumentSnapshot) => {
		this.role = decodeSchoolRole(snapshot.get('role'))
		
		return this
	}
}
