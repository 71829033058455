import React from 'react'

import School from '../../models/School'
// import useClassesTeaching from '../../hooks/useClassesTeaching'
// import useClassesEnrolled from '../../hooks/useClassesEnrolled'

export default <UserData,>({ school }: { school: School<UserData> }) => {
	// const classesTeaching = useClassesTeaching(school)
	// const classesEnrolled = useClassesEnrolled(school)
	
	return (
		<>
			<div className="classes-teaching">
				{/* {classesTeaching.map(_class => (
					<div key={_class.id}>
						<h1>{_class.name}</h1>
					</div>
				))} */}
			</div>
			<div className="classes-enrolled">
				{/* {classesEnrolled.map(_class => (
					<div key={_class.id}>
						<h1>{_class.name}</h1>
					</div>
				))} */}
			</div>
		</>
	)
}
