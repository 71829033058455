import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import School from '../../models/School'
import useCourses from '../../hooks/useCourses'
import Input from '../shared/Input'
import Loader from '../shared/Loader'
import { includesNormalized } from '../../utils'

export default <UserData,>({ school }: { school: School<UserData> }) => {
	const _courses = useCourses(school)
	
	const [query, setQuery] = useState('')
	
	const courses = _courses?.filter(course =>
		includesNormalized(query, [course.name, course.description])
	)
	
	return (
		<>
			<div className="header">
				<h1>Courses</h1>
				{school.isAdmin && (
					<Link className="new" to={`/s/${school.slug}/courses/new`}>
						<FontAwesomeIcon icon={faPlusCircle} />
					</Link>
				)}
			</div>
			<Input
				icon={faSearch}
				type="name"
				placeholder="Courses"
				value={query}
				setValue={setQuery}
			/>
			{courses
				? _courses && _courses.length
					? courses.length
						? (
							<table>
								<tr>
									<th>Name</th>
									<th>Description</th>
									<th>Manage</th>
								</tr>
								{courses.map(course => (
									<tr key={course.id}>
										<td>
											<Link to={`/s/${school.slug}/courses/${course.id}`}>
												{course.name}
											</Link>
										</td>
										<td dangerouslySetInnerHTML={{ __html: course.description }} />
										<td>
											<Link to={`/s/${school.slug}/courses/${course.id}/lessons`}>
												Lessons
											</Link>
										</td>
									</tr>
								))}
							</table>
						)
						: <p>No results</p>
					: <p>No courses yet</p>
				: <Loader size="24px" thickness="4px" color="#63b3ed" />
			}
		</>
	)
}
