import firebase from '../../firebase'

import 'firebase/firestore'

const firestore = firebase.firestore()

export interface CourseData {
	name: string
	description: string
	syllabus: string
	prerequisites: string[]
}

export default class Course implements CourseData {
	id: string
	name: string
	description: string
	syllabus: string
	prerequisites: string[]
	
    constructor(id: string, data: CourseData) {
		this.id = id
		this.name = data.name
		this.description = data.description
		this.syllabus = data.syllabus
		this.prerequisites = data.prerequisites
	}
	
	static fromSnapshot = (snapshot: firebase.firestore.DocumentSnapshot) =>
		new Course(snapshot.id, {
			name: snapshot.get('name'),
			description: snapshot.get('description'),
			syllabus: snapshot.get('syllabus'),
			prerequisites: snapshot.get('prerequisites')
		})
	
	static createForSchoolWithId = async (
		schoolId: string,
		data: {
			name: string
			description: string
			syllabus: string
		}
	) =>
		(await firestore.collection(`schools/${schoolId}/courses`).add({
			...data,
			active: true
		})).id
	
	static observeForSchoolWithId = (
		schoolId: string,
		{ initializeCourses, addCourse, updateCourse, removeCourse }: {
			initializeCourses: (schoolId: string) => void
			addCourse: (schoolId: string, snapshot: firebase.firestore.DocumentSnapshot) => void
			updateCourse: (schoolId: string, snapshot: firebase.firestore.DocumentSnapshot) => void
			removeCourse: (schoolId: string, courseId: string) => void
		}
	) =>
		firestore
			.collection(`schools/${schoolId}/courses`)
			.where('active', '==', true)
			.onSnapshot(
				snapshot => {
					initializeCourses(schoolId)
					
					for (const { type, doc } of snapshot.docChanges())
						switch (type) {
							case 'added':
								addCourse(schoolId, doc)
								break
							case 'modified':
								updateCourse(schoolId, doc)
								break
							case 'removed':
								removeCourse(schoolId, doc.id)
								break
						}
				},
				error => {
					alert(error.message)
					console.error(error)
				}
			)
	
	edit = (
		schoolId: string,
		data: {
			name: string
			description: string
			syllabus: string
		}
	) =>
		firestore.doc(`schools/${schoolId}/courses/${this.id}`).update(data)
	
	deactivate = (schoolId: string) =>
		firestore.doc(`schools/${schoolId}/courses/${this.id}`).update({
			active: false
		})
	
	updateFromSnapshot = (snapshot: firebase.firestore.DocumentSnapshot) => {
		this.name = snapshot.get('name')
		this.description = snapshot.get('description')
		this.syllabus = snapshot.get('syllabus')
		this.prerequisites = snapshot.get('prerequisites')
		
		return this
	}
}
