import React from 'react'

import Navbar from './Navbar'
import AuthBox, { AuthBoxProps } from '../shared/AuthBox'

import '../../scss/components/Auth.scss'

export default (props: AuthBoxProps) => (
	<div className="auth">
		<Navbar />
		<AuthBox {...props} />
	</div>
)
