import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { faSignature } from '@fortawesome/free-solid-svg-icons'

import CreateSchoolContext from '../../contexts/CreateSchool'
import useQuery from '../../hooks/useQuery'
import useCurrentUser from '../../hooks/useCurrentUser'
import School from '../../models/School'
import LoadingState from '../../models/LoadingState'
import {
	setCreateSchoolImage,
	setCreateSchoolName,
	setCreateSchoolShortName,
	setCreateSchoolDescription,
	setCreateSchoolSlug
} from '../../actions'
import Navbar from '../shared/Navbar'
import ImagePicker from '../shared/ImagePicker'
import Input from '../shared/Input'
import TextArea from '../shared/TextArea'
import Button from '../shared/Button'
import { urlWithQuery, compose1 } from '../../utils'

import '../../scss/components/CreateSchool.scss'

export default () => {
	const [{ image, name, shortName, description, slug }, dispatch] = useContext(CreateSchoolContext)
	const history = useHistory()
	const shouldCreate = useQuery().get('action') === 'create'
	
	const [currentUser] = useCurrentUser()
	const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone()
	
	const [imageUrl, setImageUrl] = useState(null as string | null)
	const [createLoadingState, setCreateLoadingState] = useState(LoadingState.None)
	
	const isCreateButtonLoading = createLoadingState === LoadingState.Loading
	const isCreateButtonDisabled = !(name && shortName && slug)
	
	useEffect(() => {
		if (acceptedFiles.length)
			dispatch(setCreateSchoolImage(acceptedFiles[0]))
	}, [acceptedFiles]) // eslint-disable-line
	
	useEffect(() => {
		setImageUrl(image && URL.createObjectURL(image))
	}, [image])
	
	useEffect(() => {
		if (shouldCreate && name && shortName && slug)
			create()
	}, [shouldCreate]) // eslint-disable-line
	
	const reset = () => {
		dispatch(setCreateSchoolImage(null))
		dispatch(setCreateSchoolName(''))
		dispatch(setCreateSchoolShortName(''))
		dispatch(setCreateSchoolDescription(''))
		dispatch(setCreateSchoolSlug(''))
	}
	
	const create = async () => {
		if (!currentUser)
			return history.push(urlWithQuery('/auth', {
				title: 'Before you create your school...',
				next: urlWithQuery('/new', { action: 'create' })
			}))
		
		try {
			setCreateLoadingState(LoadingState.Loading)
			
			await School.createForUserWithId(
				currentUser.id,
				{ image, name, shortName, description, slug }
			)
			
			setCreateLoadingState(LoadingState.Success)
			
			reset()
			history.push(`/s/${slug}`)
		} catch (error) {
			setCreateLoadingState(LoadingState.Fail)
			
			console.error(error)
			alert(error.message)
		}
	}
	
	return (
		<div className="create-school">
			<Navbar />
			<div className="main-box">
				<ImagePicker
					rootProps={getRootProps()}
					inputProps={getInputProps()}
					isDragging={isDragActive}
					url={imageUrl}
					removeImage={() => setImageUrl(null)}
				/>
				<div className="inputs">
					<Input
						icon={faSignature}
						type="name"
						placeholder="Name (required)"
						value={name}
						setValue={compose1(dispatch, setCreateSchoolName)}
					/>
					<Input
						icon={faSignature}
						type="name"
						placeholder="Abbreviated name (required)"
						value={shortName}
						setValue={compose1(dispatch, setCreateSchoolShortName)}
					/>
					<Input
						icon={faSignature}
						type="name"
						placeholder="Unique identifier (required)"
						value={slug}
						setValue={value =>
							dispatch(setCreateSchoolSlug(
								value.toLowerCase().replace(/\s+/g, '-')
							))
						}
					/>
					<TextArea
						minHeight={100}
						placeholder="Description (optional)"
						value={description}
						setValue={compose1(dispatch, setCreateSchoolDescription)}
					/>
				</div>
				<Button
					loaderSize="16px"
					loaderThickness="3px"
					loaderColor="#63b3ed"
					loading={isCreateButtonLoading}
					disabled={isCreateButtonDisabled}
					onClick={create}
				>
					Create
				</Button>
			</div>
		</div>
	)
}
