import React from 'react'
import { useParams } from 'react-router-dom'
import cx from 'classnames'

import useSchools from '../../hooks/useSchools'
import useEnrolledSchools from '../../hooks/useEnrolledSchools'
import useCourses from '../../hooks/useCourses'
import Navbar from '../shared/SchoolNavbar'
import Content from './Content'
import Loader from '../shared/Loader'

import '../../scss/components/CourseDisplay.scss'

export default () => {
	const { slug, courseId } = useParams()
	
	const schools = useSchools()
	const enrolledSchools = useEnrolledSchools()
	
	const school = schools.find(school => school.slug === slug)
		?? enrolledSchools.find(school => school.slug === slug)
	
	const course = useCourses(school)?.find(course => course.id === courseId)
	
	return (
		<div className={cx('course-display', { loading: !school })}>
			{school
				? (
					<>
						<Navbar school={school} />
						{course
							? <Content school={school} course={course} />
							: <Loader size="24px" thickness="4px" color="#63b3ed" />
						}
					</>
				)
				: <Loader size="24px" thickness="4px" color="#63b3ed" />
			}
		</div>
	)
}
