import React, { createContext, Dispatch, PropsWithChildren, useReducer } from 'react'

import Action, { ActionType } from '../actions/Action'

export interface CreateSchoolState {
	image: File | null
	name: string
	shortName: string
	description: string
	slug: string
}

export type CreateSchoolAction = Action<
	| File | null // SetCreateSchoolImage
	| string // SetCreateSchoolName, SetCreateSchoolShortName, SetCreateSchoolDescription, SetCreateSchoolSlug
>

const initialState: CreateSchoolState = {
	image: null,
	name: '',
	shortName: '',
	description: '',
	slug: ''
}

const reducer = (state: CreateSchoolState, { type, payload }: CreateSchoolAction) => {
	switch (type) {
		case ActionType.SetCreateSchoolImage:
			return { ...state, image: payload as File | null }
		case ActionType.SetCreateSchoolName:
			return { ...state, name: payload as string }
		case ActionType.SetCreateSchoolShortName:
			return { ...state, shortName: payload as string }
		case ActionType.SetCreateSchoolDescription:
			return { ...state, description: payload as string }
		case ActionType.SetCreateSchoolSlug:
			return { ...state, slug: payload as string }
		default:
			return state
	}
}

const Context = createContext<[CreateSchoolState, Dispatch<CreateSchoolAction>]>([
	initialState,
	console.log
])
export default Context

export const CreateSchoolProvider = ({ children }: PropsWithChildren<{}>) => (
	<Context.Provider value={useReducer(reducer, initialState)}>
		{children}
	</Context.Provider>
)
