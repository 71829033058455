import React, { createContext, Dispatch, PropsWithChildren, useReducer } from 'react'

import Action, { ActionType } from '../actions/Action'
import Course from '../models/Course'

export type CoursesState = Record<string, Course[]>

export type CoursesAction = Action<
	| string // InitializeCards
	| { schoolId: string, snapshot: firebase.firestore.DocumentSnapshot } // AddCourse, UpdateCourse
	| { schoolId: string, courseId: string } // RemoveCourse
>

const initialState: CoursesState = {}

const reducer = (state: CoursesState, { type, payload }: CoursesAction) => {
	switch (type) {
		case ActionType.InitializeCourses: {
			const schoolId = payload as string
			
			return {
				...state,
				[schoolId]: state[schoolId] ?? []
			}
		}
		case ActionType.AddCourse: {
			const { schoolId, snapshot } = payload as {
				schoolId: string
				snapshot: firebase.firestore.DocumentSnapshot
			}
			
			return {
				...state,
				[schoolId]: [
					...state[schoolId] ?? [],
					Course.fromSnapshot(snapshot)
				]
			}
		}
		case ActionType.UpdateCourse: {
			const { schoolId, snapshot } = payload as {
				schoolId: string
				snapshot: firebase.firestore.DocumentSnapshot
			}
			
			return {
				...state,
				[schoolId]: state[schoolId]?.map(course =>
					course.id === snapshot.id
						? course.updateFromSnapshot(snapshot)
						: course
				)
			}
		}
		case ActionType.RemoveCourse: {
			const { schoolId, courseId } = payload as {
				schoolId: string
				courseId: string
			}
			
			return {
				...state,
				[schoolId]: state[schoolId]?.filter(course =>
					course.id !== courseId
				)
			}
		}
		default:
			return state
	}
}

const Context = createContext<[CoursesState, Dispatch<CoursesAction>]>([
	initialState,
	console.log
])
export default Context

export const CoursesProvider = ({ children }: PropsWithChildren<{}>) => (
	<Context.Provider value={useReducer(reducer, initialState)}>
		{children}
	</Context.Provider>
)
