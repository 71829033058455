import React, { HTMLAttributes } from 'react'
import { Link } from 'react-router-dom'

import '../../scss/components/Navbar.scss'

export default ({ children, ...props }: HTMLAttributes<HTMLDivElement>) => (
	<div {...props} className="navbar">
		<Link to="/">
			<h1>Instructive Space</h1>
		</Link>
		<div className="items">{children}</div>
	</div>
)
