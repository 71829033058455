import React from 'react'
import { Link } from 'react-router-dom'

import SignOutButton from '../shared/SignOutButton'
import requiresAuth from '../../hooks/requiresAuth'
import useSchools from '../../hooks/useSchools'
import useEnrolledSchools from '../../hooks/useEnrolledSchools'
import SchoolRow from './SchoolRow'

import '../../scss/components/Dashboard.scss'

export default () => {
	requiresAuth('/')
	
	return (
		<div className="dashboard home">
			<h1>Instructive Space - Dashboard</h1>
			<div className="options">
				<Link to="/new">
					Create school
				</Link>
				<SignOutButton>
					Sign out
				</SignOutButton>
			</div>
			<div className="schools-container">
				<div className="left">
					<h2>Schools</h2>
					<div className="list">
						{useSchools().map(school => (
							<SchoolRow key={school.id} school={school} />
						))}
					</div>
				</div>
				<div className="right">
					<h2>Enrolled schools</h2>
					<div className="list">
						{useEnrolledSchools().map(school => (
							<SchoolRow key={school.id} school={school} />
						))}
					</div>
				</div>
			</div>
		</div>
	)
}
