import firebase from 'firebase/app'

firebase.initializeApp({
	apiKey: 'AIzaSyBfaUFmH3mx2oCAjpCcGH8HksuJdGlsjoE',
	authDomain: 'instructive-space.firebaseapp.com',
	databaseURL: 'https://instructive-space.firebaseio.com',
	projectId: 'instructive-space',
	storageBucket: 'instructive-space.appspot.com',
	messagingSenderId: '724437635782',
	appId: '1:724437635782:web:2066fd317f14c2746f7dc7',
	measurementId: 'G-ZS9EW5EF0D'
})

export default firebase
