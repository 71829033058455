import { ActionType } from './Action'
import firebase from '../firebase'
import LoadingState from '../models/LoadingState'

// Current user

export const setCurrentUser = (firebaseUser: firebase.User | null) => ({
	type: ActionType.SetCurrentUser,
	payload: firebaseUser
})

export const updateCurrentUser = (snapshot: firebase.firestore.DocumentSnapshot) => ({
	type: ActionType.UpdateCurrentUser,
	payload: snapshot
})

export const setCurrentUserLoadingState = (loadingState: LoadingState) => ({
	type: ActionType.SetCurrentUserLoadingState,
	payload: loadingState
})

export const setIsObservingCurrentUser = (value: boolean) => ({
	type: ActionType.SetIsObservingCurrentUser,
	payload: value
})

// Schools

export const updateSchool = (
	snapshot: firebase.firestore.DocumentSnapshot,
	userDataSnapshot: firebase.firestore.DocumentSnapshot,
	isEnrolled: boolean
) => ({
	type: ActionType.UpdateSchool,
	payload: { snapshot, userDataSnapshot, isEnrolled }
})

export const updateSchoolUserData = (
	snapshot: firebase.firestore.DocumentSnapshot,
	isEnrolled: boolean
) => ({
	type: ActionType.UpdateSchoolUserData,
	payload: { snapshot, isEnrolled }
})

export const removeSchool = (schoolId: string, isEnrolled: boolean) => ({
	type: ActionType.RemoveSchool,
	payload: { schoolId, isEnrolled }
})

export const setIsObservingSchools = (
	value: boolean,
	isEnrolled: boolean
) => ({
	type: ActionType.SetIsObservingSchools,
	payload: { value, isEnrolled }
})

// School image urls

export const setSchoolImageUrl = (schoolId: string, url: string | null) => ({
	type: ActionType.SetSchoolImageUrl,
	payload: { schoolId, url }
})

export const setSchoolImageUrlLoadingState = (schoolId: string, loadingState: LoadingState) => ({
	type: ActionType.SetSchoolImageUrlLoadingState,
	payload: { schoolId, loadingState }
})

// Create school

export const setCreateSchoolImage = (value: File | null) => ({
	type: ActionType.SetCreateSchoolImage,
	payload: value
})

export const setCreateSchoolName = (value: string) => ({
	type: ActionType.SetCreateSchoolName,
	payload: value
})

export const setCreateSchoolShortName = (value: string) => ({
	type: ActionType.SetCreateSchoolShortName,
	payload: value
})

export const setCreateSchoolDescription = (value: string) => ({
	type: ActionType.SetCreateSchoolDescription,
	payload: value
})

export const setCreateSchoolSlug = (value: string) => ({
	type: ActionType.SetCreateSchoolSlug,
	payload: value
})

// Courses

export const initializeCourses = (schoolId: string) => ({
	type: ActionType.InitializeCourses,
	payload: schoolId
})

export const addCourse = (
	schoolId: string,
	snapshot: firebase.firestore.DocumentSnapshot
) => ({
	type: ActionType.AddCourse,
	payload: { schoolId, snapshot }
})

export const updateCourse = (
	schoolId: string,
	snapshot: firebase.firestore.DocumentSnapshot
) => ({
	type: ActionType.UpdateCourse,
	payload: { schoolId, snapshot }
})

export const removeCourse = (schoolId: string, courseId: string) => ({
	type: ActionType.RemoveCourse,
	payload: { schoolId, courseId }
})
