import { useContext, useEffect } from 'react'

import SchoolImageUrlsContext from '../contexts/SchoolImageUrls'
import School from '../models/School'
import LoadingState from '../models/LoadingState'
import { setSchoolImageUrl, setSchoolImageUrlLoadingState } from '../actions'
import { compose2 } from '../utils'

export default <UserData>(school: School<UserData>): [string | null, LoadingState] => {
	const [{ [school.id]: _state }, dispatch] = useContext(SchoolImageUrlsContext)
	
	const state = _state ?? { url: null, loadingState: LoadingState.None }
	
	useEffect(() => {
		if (!(school.hasImage && state.loadingState === LoadingState.None))
			return
		
		school.loadImageUrl({
			setImageUrl: compose2(dispatch, setSchoolImageUrl),
			setImageUrlLoadingState: compose2(dispatch, setSchoolImageUrlLoadingState)
		})
	}, [school.hasImage, state.loadingState]) // eslint-disable-line
	
	return [state.url, state.loadingState]
}
