import React from 'react'
import { Link } from 'react-router-dom'

import School from '../../models/School'
import useImageUrl from '../../hooks/useImageUrl'

export default <UserData,>({ school }: { school: School<UserData> }) => {
	const [imageUrl] = useImageUrl(school)
	
	return (
		<Link to={`/s/${school.slug}`}>
			{imageUrl && <img src={imageUrl} alt={school.name} />}
			<p>{school.name}</p>
		</Link>
	)
}
