import firebase from '../../firebase'
import AdministratorUserData, { SchoolRole } from './UserData'
import EnrolledUserData from './EnrolledUserData'
import LoadingState from '../LoadingState'

import 'firebase/firestore'
import 'firebase/storage'

const firestore = firebase.firestore()
const storage = firebase.storage().ref()

export interface SchoolData {
	name: string
	hasImage: boolean
    shortName: string
    description: string
    slug: string
    numberOfActiveStudents: number
    numberOfTotalStudents: number
}

export default class School<UserData> implements SchoolData {
	id: string
	hasImage: boolean
    name: string
    shortName: string
    description: string
    slug: string
    numberOfActiveStudents: number
	numberOfTotalStudents: number
	
	userData: UserData | null
	
    constructor(id: string, data: SchoolData, userData: UserData | null) {
		this.id = id
		this.hasImage = data.hasImage
        this.name = data.name
        this.shortName = data.shortName
		this.description = data.description
		this.slug = data.slug
		this.numberOfActiveStudents = data.numberOfActiveStudents
		this.numberOfTotalStudents = data.numberOfTotalStudents
		
		this.userData = userData
	}
	
	static fromSnapshot = <UserData>(snapshot: firebase.firestore.DocumentSnapshot, userData: UserData | null) =>
		new School(snapshot.id, {
			hasImage: snapshot.get('hasImage'),
			name: snapshot.get('name'),
			shortName: snapshot.get('shortName'),
			description: snapshot.get('description'),
			slug: snapshot.get('slug'),
			numberOfActiveStudents: snapshot.get('activeStudentCount'),
			numberOfTotalStudents: snapshot.get('totalStudentCount')
		}, userData)
	
	static createForUserWithId = async (
		uid: string,
		{ image, name, shortName, description, slug }: {
			image: File | null
			name: string
			shortName: string
			description: string
			slug: string
		}
	) => {
		const validationMessage = await School.validateSlug(slug)
		
		if (validationMessage)
			throw new Error(validationMessage)
		
		const { id: schoolId } = await firestore.collection('schools').add({
			hasImage: Boolean(image),
			name,
			shortName,
			description,
			slug
		})
		
		if (image)
			await storage.child(`schools/${schoolId}`).put(image, {
				contentType: image.type,
				customMetadata: { owner: uid }
			})
		
		await firestore.doc(`users/${uid}/schools/${schoolId}`).set({
			role: 'admin',
			joined: firebase.firestore.FieldValue.serverTimestamp()
		})
		
		return schoolId
	}
	
	static observeForUserWithId = (
		uid: string,
		isEnrolled: boolean,
		{ updateSchool, updateSchoolUserData, removeSchool }: {
			updateSchool: (
				snapshot: firebase.firestore.DocumentSnapshot,
				userDataSnapshot: firebase.firestore.DocumentSnapshot,
				isEnrolled: boolean
			) => void
			updateSchoolUserData: (
				snapshot: firebase.firestore.DocumentSnapshot,
				isEnrolled: boolean
			) => void
			removeSchool: (schoolId: string, isEnrolled: boolean) => void
		}
	) =>
		firestore
			.collection(`users/${uid}/${isEnrolled ? 'enrolledS' : 's'}chools`)
			.onSnapshot(
				snapshot => {
					for (const { type, doc } of snapshot.docChanges())
						switch (type) {
							case 'added':
								firestore.doc(`schools/${doc.id}`).onSnapshot(
									snapshot => updateSchool(snapshot, doc, isEnrolled),
									error => {
										alert(error.message)
										console.error(error)
									}
								)
								break
							case 'modified':
								updateSchoolUserData(doc, isEnrolled)
								break
							case 'removed':
								removeSchool(doc.id, isEnrolled)
								break
						}
				},
				error => {
					alert(error.message)
					console.error(error)
				}
			)
	
	static validateSlug = async (slug: string): Promise<string | null> => {
		if (!slug)
			return 'A school\'s unique identifier may not be empty'
		
		if (slug.includes(' '))
			return 'A school\'s unique identifier may not contain spaces'
		
		const { docs } = await firestore
			.collection('schools')
			.where('slug', '==', slug)
			.limit(1)
			.get()
		
		return docs.length ? 'This unique identifier is already in use' : null
	}
	
	get isEnrolled() {
		return (this.userData as AdministratorUserData | EnrolledUserData | null)?.isEnrolled ?? true
	}
	
	get isTeacher() {
		return this.userData instanceof AdministratorUserData && this.userData.role === SchoolRole.Teacher
	}
	
	get isAdmin() {
		return this.userData instanceof AdministratorUserData && this.userData.role === SchoolRole.Admin
	}
	
	updateFromSnapshot = (snapshot: firebase.firestore.DocumentSnapshot) => {
		this.hasImage = snapshot.get('hasImage')
		this.name = snapshot.get('name')
		this.shortName = snapshot.get('shortName')
		this.description = snapshot.get('description')
		this.slug = snapshot.get('slug')
		this.numberOfActiveStudents = snapshot.get('activeStudentCount')
		this.numberOfTotalStudents = snapshot.get('totalStudentCount')
		
		return this
	}
	
	loadImageUrl = async (
		{ setImageUrl, setImageUrlLoadingState }: {
			setImageUrl: (schoolId: string, url: string | null) => void
			setImageUrlLoadingState: (schoolId: string, loadingState: LoadingState) => void
		}
	) => {
		try {
			setImageUrlLoadingState(this.id, LoadingState.Loading)
			
			setImageUrl(this.id, await storage.child(`schools/${this.id}`).getDownloadURL())
			setImageUrlLoadingState(this.id, LoadingState.Success)
		} catch (error) {
			setImageUrl(this.id, null)
			setImageUrlLoadingState(this.id, LoadingState.Fail)
			
			console.error(error)
		}
	}
}
