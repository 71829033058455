import React from 'react'
import CKEditor from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-instructive-space'

import '../../types/ckeditor5-react.d'
import '../../types/ckeditor5-instructive-space.d'

export default (
	{ schoolId, data, setData }: {
		schoolId: string
		data: string
		setData: (data: string) => void
	}
) => (
	<CKEditor
		editor={Editor}
		data={data}
		onChange={(_event: any, editor: any) =>
			setData(editor.getData())
		}
		config={{
			simpleUpload: {
				uploadUrl: `https://instructive.space/_api/upload-school-asset?school=${schoolId}`
			}
		}}
	/>
)
