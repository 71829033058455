export default interface Action<Payload> {
	type: ActionType
	payload: Payload
}

export enum ActionType {
	SetCurrentUser = 'SET_CURRENT_USER',
	UpdateCurrentUser = 'UPDATE_CURRENT_USER',
	SetCurrentUserLoadingState = 'SET_CURRENT_USER_LOADING_STATE',
	SetIsObservingCurrentUser = 'SET_IS_OBSERVING_CURRENT_USER',
	
	UpdateSchool = 'UPDATE_SCHOOL',
	UpdateSchoolUserData = 'UPDATE_SCHOOL_USER_DATA',
	RemoveSchool = 'REMOVE_SCHOOL',
	SetIsObservingSchools = 'SET_IS_OBSERVING_SCHOOLS',
	
	SetSchoolImageUrl = 'SET_SCHOOL_IMAGE_URL',
	SetSchoolImageUrlLoadingState = 'SET_SCHOOL_IMAGE_URL_LOADING_STATE',
	
	SetCreateSchoolImage = 'SET_CREATE_SCHOOL_IMAGE',
	SetCreateSchoolName = 'SET_CREATE_SCHOOL_NAME',
	SetCreateSchoolShortName = 'SET_CREATE_SCHOOL_SHORT_NAME',
	SetCreateSchoolDescription = 'SET_CREATE_SCHOOL_DESCRIPTION',
	SetCreateSchoolSlug = 'SET_CREATE_SCHOOL_SLUG',
	
	InitializeCourses = 'INITIALIZE_COURSES',
	AddCourse = 'ADD_COURSE',
	UpdateCourse = 'UPDATE_COURSE',
	RemoveCourse = 'REMOVE_COURSE'
}
